<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs>
      <b-breadcrumb-item active :text="`Dashboard - ${$store.state.settings.app.current.title}`"/>
    </template>
    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>
    <template #loading>
      <template>
       Loading...
      </template>
    </template>
    <template #content>
      <template v-if="error">
        <b-alert show variant="danger">
          <b-icon-exclamation-circle/>
          <span class="align-middle ml-50"> {{ error }}</span>
        </b-alert>
      </template>

      <welcome-card :user="user" />

      <b-row>
        <!-- Stats -->
        <b-col v-if="can('applications-stat')">
          <applications-stat-card
              :user="user"
              :festivals="festivals.items"
              :selections="selections.items"
              :applications="applications.items"
              :loading="festivals.loading || selections.loading || applications.loading" />
        </b-col>
        <b-col v-if="can('students-stat')">
          <students-stat-card
              :user="user"
              :festivals="festivals.items"
              :selections="selections.items"
              :applications="applications.items"
              :students="students.items"
              :loading="festivals.loading || students.loading || applications.loading || selections.loading" />
        </b-col>
        <b-col v-if="can('selections-stat')">
          <selections-stat-card
              :user="user"
              :selections="selections.items"
              :applications="applications.items"
              :loading="selections.loading || applications.loading" />
        </b-col>
        <b-col v-if="can('festivals-stat')">
          <festival-stat-card
              :user="user"
              :festivals="festivals.items"
              :selections="selections.items"
              :applications="applications.items"
              :loading="festivals.loading || selections.loading || applications.loading" />
        </b-col>
        <b-col v-if="can('instruments-stat')">
          <instruments-stat-card
              :user="user"
              :instruments="instruments.items"
              :festivals="festivals.items"
              :applications="applications.items"
              :selections="selections.items"
              :loading="instruments.loading || festivals.loading || selections.loading || applications.loading " />
        </b-col>
        <b-col v-if="can( 'ensembles-stat')">
          <ensembles-stat-card
              :user="user"
              :ensembles="ensembles.items"
              :applications="applications.items"
              :selections="selections.items"
              :loading="ensembles.loading || applications.loading || selections.loading" />
        </b-col>
      </b-row>
      <b-row>
        <!-- Tables -->
        <b-col>
          <recent-applications v-if="can( 'applications-table')"
              :user="user"
              :festivals="festivals.items"
              :forms="forms.items"
              :instruments="instruments.items"
              :applications="applications.items"
              :loading="festivals.loading || forms.loading || instruments.loading || applications.loading"/>

          <recent-selections v-if="can('selections-table')"
              :user="user"
              :ensembles="ensembles.items"
              :selections="selections.items"
              :applications="applications.items"
              :loading="ensembles.loading || selections.loading || applications.loading"/>


          <ensembles-table v-if="can('ensembles-table')"
             :user="user"
             :instruments="instruments.items"
             :ensembles="ensembles.items"
             :selections="selections.items"
             :loading="instruments.loading || ensembles.loading || selections.loading"/>

        </b-col>

        <!-- Charts -->
        <b-col v-if="can('application-submission-chart') || can('scored-and-selected-chart') || can('scored-and-selected-instruments-chart') || can('accepted-and-declined-chart')" cols="4">
          <application-submission-chart
            v-if="can('application-submission-chart')"
            :user="user"
            :applications="applications.items"
            :loading="applications.loading"/>

          <scored-and-selected-chart
            v-if="can('scored-and-selected-chart')"
            :user="user"
            :applications="applications.items"
            :loading="applications.loading"/>

          <scored-and-selected-instruments-chart
            v-if="can('scored-and-selected-instruments-chart')"
            :user="user"
            :festivals="festivals.items"
            :instruments="instruments.items"
            :zones="zones.items"
            :applications="applications.items"
            :selections="selections.items"
            :loading="festivals.loading || instruments.loading || zones.loading || applications.loading || selections.loading"/>

          <accepted-and-declined-chart
            v-if="can('accepted-and-declined-chart')"
            :user="user"
            :selections="selections.items"
            :loading="selections.loading"/>

        </b-col>
      </b-row>

    </template>
  </page-layout>
</template>

<script>
import {API, Auth, graphqlOperation} from 'aws-amplify';
import PageLayout from '@/components/PageLayout.vue';
import RecentApplications from './RecentApplications.vue';
import RecentSelections from './RecentSelections.vue';
import EnsemblesTable from './EnsemblesTable.vue';
import FestivalStatCard from './FestivalStatCard.vue';
import InstrumentsStatCard from './InstrumentsStatCard.vue';
import ApplicationsStatCard from './ApplicationsStatCard.vue';
import SelectionsStatCard from './SelectionsStatCard.vue';
import StudentsStatCard from './StudentsStatCard.vue';
import EnsemblesStatCard from './EnsemblesStatCard.vue';
import ApplicationSubmissionChart from './ApplicationSubmissionChart.vue';
import ScoredAndSelectedChart from './ScoredAndSelectedChart.vue';
import ScoredAndSelectedInstrumentsChart from './ScoredAndSelectedInstrumentsChart.vue';
import AcceptedAndDeclinedChart from './AcceptedAndDeclinedChart.vue';
import WelcomeCard from './WelcomeCard.vue';
import {
  getUser,
  listApplications, listApplicationsByTeacher,
  listEnsembles,
  listFestivals,
  listForms,
  listInstruments,
  listStudents,
  listSelections, listZones, listSchools
} from './queries/dashboard';
import settingsMixin from '@/mixins/settings.mixin';


export default {
  name: 'Dashboard',
  components: {
    ApplicationSubmissionChart,
    WelcomeCard,
    RecentSelections,
    EnsemblesTable,
    ScoredAndSelectedChart,
    ScoredAndSelectedInstrumentsChart,
    AcceptedAndDeclinedChart,
    StudentsStatCard,
    ApplicationsStatCard,
    FestivalStatCard,
    InstrumentsStatCard,
    EnsemblesStatCard,
    SelectionsStatCard,
    RecentApplications,
    PageLayout,
  },
  mixins: [settingsMixin],
  data() {
    return {
      user: null,
      festivals: {
        items: [],
        loading: true
      },
      instruments: {
        items: [],
        loading: true
      },
      forms: {
        items: [],
        loading: true
      },
      zones: {
        items: [],
        loading: true
      },
      schools: {
        items: [],
        loading: true
      },
      students: {
        items: [],
        loading: true
      },
      ensembles: {
        items: [],
        loading: true
      },
      applications: {
        items: [],
        loading: true
      },
      selections: {
        items: [],
        loading: true
      },
      error: null,
    }
  },
  async mounted() {
    await this.getUser()
    this.refresh(false)
  },
  methods: {
    async getUser() {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const cognitoGroups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
      const input = { id: cognitoUser.attributes['custom:user_id'] }
      if(cognitoGroups.includes('Teacher')) {
        input.includeSchools = true
        input.includeDistrict = true
      }

      const amplifyUser = await API.graphql(graphqlOperation(getUser, input));
      this.user = {
        id: cognitoUser.attributes['custom:user_id'],
        groups: cognitoGroups,
        verified: cognitoUser.attributes?.email_verified || false,
        name: amplifyUser.data?.getUser?.name,
        ensembleIds: amplifyUser.data?.getUser?.ensembleIds || [],
        instrumentIds: amplifyUser.data?.getUser?.instrumentIds || [],
        zoneIds: amplifyUser.data?.getUser?.zoneIds || [],
        schools: amplifyUser.data?.getUser?.schools || { items: [] }
      }
    },

    /** Festivals **/
    async getFestivals() {
      if(this.$store.getters['storageLocal/isRouteDisabled']('dashboard') || this.$store.getters['dashboard/isExpired']('festivals')) {
        await this.listFestivals();
      }
      else {
        await this.listFestivalsFromCache()
      }
    },
    async listFestivals(nextToken, pagedFestivals) {
      const festivals = pagedFestivals || []

      const input = {
        limit: 1000,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken
      }
      if(this.isZoneRep()) {
        input.filter.or = [ ]
        this.user.zoneIds.forEach(id => {
          input.filter.or.push({ festivalZoneId: { eq: id } })
        })
      }

      const response = await API.graphql(graphqlOperation(listFestivals, input));
      festivals.push(...response.data.listFestivals.items);

      if(response.data.listFestivals.nextToken) {
        await this.listFestivals(response.data.listFestivals.nextToken, festivals)
      }
      else {
        /** We skipped querying the festivals, forms, and instruments from the application, as we need them for the filters
         * As we have the information, we can just match it up their IDS and map the data in. **/
        this.festivals.items = festivals
        this.festivals.loading = false
        await this.$store.dispatch(`${this.$route.name}/setFestivals`, {
          key: this.$route.name,
          items: this.festivals.items
        })
      }
    },
    async listFestivalsFromCache() {
      this.festivals.items = this.$store.getters['dashboard/getFestivals'].items
      this.festivals.loading = false
    },

    /** Instruments **/
    async getInstruments() {
      if(this.$store.getters['storageLocal/isRouteDisabled']('dashboard') || this.$store.getters['dashboard/isExpired']('instruments')) {
        await this.listInstruments();
      }
      else {
        await this.listInstrumentsFromCache()
      }
    },
    async listInstruments(nextToken, pagedInstruments) {
      const instruments = pagedInstruments || []
      const input = { limit: 1000, nextToken: nextToken }
      const response = await API.graphql(graphqlOperation(listInstruments, input));
      instruments.push(...response.data.listInstruments.items);

      if(response.data.listInstruments.nextToken) {
        await this.listInstruments(response.data.listInstruments.nextToken, instruments)
      }
      else {
        /** We skipped querying the festivals, forms, and instruments from the application, as we need them for the filters
         * As we have the information, we can just match it up their IDS and map the data in. **/
        this.instruments.items = instruments
        this.instruments.loading = false
        await this.$store.dispatch(`${this.$route.name}/setInstruments`, {
          key: this.$route.name,
          items: this.instruments.items
        })
      }
    },
    async listInstrumentsFromCache() {
      this.instruments.items = this.$store.getters['dashboard/getInstruments'].items
      this.instruments.loading = false
    },

    /** Forms **/
    async getForms() {
      if(this.$store.getters['storageLocal/isRouteDisabled']('dashboard') || this.$store.getters['dashboard/isExpired']('forms')) {
        await this.listForms();
      }
      else {
        await this.listFormsFromCache()
      }
    },
    async listForms(nextToken, pagedItems) {
      const items = pagedItems || []

      const input = { limit: 1000, nextToken: nextToken }
      const response = await API.graphql(graphqlOperation(listForms, input));
      items.push(...response.data.listForms.items);

      if(response.data.listForms.nextToken) {
        await this.listForms(response.data.listForms.nextToken, items)
      }
      else {
        this.forms.items = items
        this.forms.loading = false
        await this.$store.dispatch('dashboard/setForms', {
          key: this.$route.name,
          items: this.forms.items
        })
      }
    },
    async listFormsFromCache() {
      this.forms.items = this.$store.getters['dashboard/getForms'].items
      this.forms.loading = false
    },

    /** Zones **/
    async getZones() {
      if(this.$store.getters['storageLocal/isRouteDisabled']('dashboard') || this.$store.getters['dashboard/isExpired']('zones')) {
        await this.listZones();
      }
      else {
        await this.listZonesFromCache()
      }
    },
    async listZones(nextToken, pagedItems) {
      const items = pagedItems || []
      const input = { limit: 1000, nextToken: nextToken }
      const response = await API.graphql(graphqlOperation(listZones, input));
      items.push(...response.data.listZones.items);

      if(response.data.listZones.nextToken) {
        await this.listZones(response.data.listZones.nextToken, items)
      }
      else {
        this.zones.items = items
        this.zones.loading = false
        await this.$store.dispatch('dashboard/setZones', {
          key: this.$route.name,
          items: this.zones.items
        })
      }
    },
    async listZonesFromCache() {
      this.zones.items = this.$store.getters['dashboard/getZones'].items
      this.zones.loading = false
    },

    /** Ensembles **/
    async getEnsembles() {
      if(this.$store.getters['storageLocal/isRouteDisabled']('dashboard') || this.$store.getters['dashboard/isExpired']('ensembles')) {
        await this.listEnsembles();
      }
      else {
        await this.listEnsemblesFromCache()
      }
    },
    async listEnsembles(nextToken, pagedItems) {
      const items = pagedItems || []
      const input = { limit: 1000, nextToken: nextToken }

      if(this.can('ensembles-table')) {
        input.includeInstruments = true
      }

      const response = await API.graphql(graphqlOperation(listEnsembles, input));
      items.push(...response.data.listEnsembles.items);

      if(response.data.listEnsembles.nextToken) {
        await this.listEnsembles(response.data.listEnsembles.nextToken, items)
      }
      else {
        this.ensembles.items = items
        this.ensembles.loading = false
        await this.$store.dispatch('dashboard/setEnsembles', {
          key: this.$route.name,
          items: this.ensembles.items
        })
      }
    },
    async listEnsemblesFromCache() {
      this.ensembles.items = this.$store.getters['dashboard/getEnsembles'].items
      this.ensembles.loading = false
    },


    /** Schools **/
    async listSchools() {
      let nextToken = null;
      const items = [];

      do {
        const input = {
          limit: 1000,
          nextToken: nextToken
        };

        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listSchools, input));
        items.push(...response.data.listSchools.items);
        nextToken = response.data.listSchools.nextToken;
      }
      while (nextToken);


      this.schools.items = items;
      this.schools.loading = false
    },

    /** Students **/
    async listStudents() {
      let nextToken = null;
      const items = [];

      do {
        const input = {
          limit: 1000,
          filter: {
            createdAt: {
              between: [
                this.settingsStore.app.current.year.start,
                this.settingsStore.app.current.year.end
              ]
            }
          },
          nextToken: nextToken
        };

        if(this.isTeacher()) {
          //input.filter.teacherID = { eq: this.user.id }
        }

        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listStudents, input));
        items.push(...response.data.listStudents.items);
        nextToken = response.data.listStudents.nextToken;
      }
      while (nextToken);

      this.students.items = items;
      this.students.loading = false
    },

    /** Applications **/
    async listApplications() {
      let nextToken = null;
      const items = [];

      do {
        const input = {
          limit: 1000,
          filter: {
            createdAt: {
              between: [
                this.settingsStore.app.current.year.start,
                this.settingsStore.app.current.year.end
              ]
            }
          },
          nextToken: nextToken
        };

        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listApplications, input));
        items.push(...response.data.listApplications.items);
        nextToken = response.data.listApplications.nextToken;
      } while (nextToken);

      const festivalsMap = new Map(this.festivals.items.map(festival => [festival.id, festival]));
      const formsMap = new Map(this.forms.items.map(form => [form.id, form]));
      const instrumentsMap = new Map(this.instruments.items.map(instrument => [instrument.id, instrument]));
      const studentsMap = new Map(this.students.items.map(student => [student.id, student]));

      items.forEach(item => {
        item.festival = festivalsMap.get(item.applicationFestivalId);
        item.form = formsMap.get(item.applicationFormId);
        item.instrument = instrumentsMap.get(item.applicationInstrumentId);
        item.student = studentsMap.get(item.studentApplicationsId);
      })

      this.applications.items = items;
      this.applications.loading = false;
    },

    async listApplicationsByTeacher() {
      let nextToken = null;
      const items = [];

      do {
        const input = {
          limit: 500,
          teacherID: this.user.id,
          filter: {
            createdAt: {
              between: [
                this.settingsStore.app.current.year.start,
                this.settingsStore.app.current.year.end
              ]
            }
          },
          nextToken: nextToken
        };

        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listApplicationsByTeacher, input));
        items.push(...response.data.listApplicationsByTeacher.items);
        nextToken = response.data.listApplicationsByTeacher.nextToken;
      } while (nextToken);

      const festivalsMap = new Map(this.festivals.items.map(festival => [festival.id, festival]));
      const formsMap = new Map(this.forms.items.map(form => [form.id, form]));
      const instrumentsMap = new Map(this.instruments.items.map(instrument => [instrument.id, instrument]));
      const studentsMap = new Map(items.map(student => [student.id, student]));
      this.students.items.push(...studentsMap.values())

      items.forEach(item => {
        item.festival = festivalsMap.get(item.applicationFestivalId);
        item.form = formsMap.get(item.applicationFormId);
        item.instrument = instrumentsMap.get(item.applicationInstrumentId);
      })

      this.applications.items = items;
      this.applications.loading = false;
    },

    /** Selections **/
    async listSelections(nextToken, pagedItems) {
      const items = pagedItems || []

      const input = {
        limit: 1000,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken
      }
      const response = await API.graphql(graphqlOperation(listSelections, input));
      items.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, items)
      }
      else {
        this.selections.items = items.map(selection => ({ ...selection,
              ensemble: this.ensembles.items.find(ensemble => ensemble.id === selection.selectionEnsembleId),
              application: this.applications.items.find(application => application.id === selection.selectionApplicationId),
            }
        ))
        this.selections.loading = false
      }
    },
    async setSelections(items) {
      this.selections.items = items
      this.selections.loading = false
    },


    /** Group Checks **/
    can(field) {
      if(field) {
        return this.$can('read', 'dashboard', field)
      }
      return this.$can('read', 'dashboard')
    },
    isAdmin() {
      return (this.user.groups.includes('Admin') || this.user.groups.includes('NYSSMA_Admin'));
    },
    isTeacher() {
      return this.user.groups.includes('Teacher');
    },
    isChair() {
      return this.user.groups.includes('NYSSMA_Chair');
    },
    isZoneRep() {
      return this.user.groups.includes('ZoneRep');
    },


    async showLoading() {
      this.$refs.layout.state.loading = true
      this.$nextTick(() => {
        setTimeout(() => { this.$refs.layout.state.loading = false }, 500)
      })
    },
    refresh(clearData = true) {
      this.$refs.layout.state.loading = true
      if(clearData) {
        this.festivals.loading = true
        this.festivals.items = []
        this.instruments.loading = true
        this.instruments.items = []
        this.forms.loading = true
        this.forms.items = []
        this.zones.loading = true
        this.zones.items = []
        this.students.loading = true
        this.students.items = []
        this.ensembles.loading = true
        this.ensembles.items = []
        this.students.loading = true
        this.students.items = []
        this.applications.loading = true
        this.applications.items = []
        this.selections.loading = true
        this.selections.items = []
      }
      this.$refs.layout.state.loading = false


      const promises = []
      if(this.can('listFestivals')) {
        const festivals = this.getFestivals()
        promises.push(festivals)
      } else { this.festivals.loading = false }

      if(this.can('listForms')) {
        const forms = this.getForms()
        promises.push(forms)
      } else { this.forms.loading = false }

      if(this.can('listInstruments')) {
        const instruments = this.getInstruments()
        promises.push(instruments)
      } else { this.instruments.loading = false }

      if(this.can('listZones')) {
        const zones = this.getZones()
        promises.push(zones)
      } else { this.zones.loading = false }

      if(this.can('listEnsembles')) {
        const ensembles = this.getEnsembles()
        promises.push(ensembles)
      } else { this.ensembles.loading = false }

      if(this.can('listSelections') || this.can('listApplications')) {
        const students = this.listStudents()
        promises.push(students)
      }
      else { this.students.loading = false }

      Promise.all(promises).then(() => {
        if(this.can('listSelections')) {
          this.listSelections()
        } else { this.selections.loading = false }

        if(this.can('listApplications')) {
          this.listApplications()
        }
        else if(this.can('listApplicationsByTeacher')) {
          this.listApplicationsByTeacher()
        }
        else { this.applications.loading = false }
      });
    },
    setError(message) {
      this.error = message
    }
  }
}
</script>

<style scoped>

</style>
